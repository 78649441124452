<template>
    <div class="shop_main" ref="scroll">
        <div class="shop_search_menu">
            <a href="">首页</a>
            <i class="el-icon-arrow-right"></i>
            <span class="on">{{listQuery.name}}</span>
        </div>

        <div class="shop_list_sort">
            <div class="sort">
                <ul>
                    <li :class="listQuery.desc === 0 ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeDesc(0)"><span>默认排序</span></a></li>
                    <li :class="listQuery.desc === 1 ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeDesc(1)"><span>成交量</span></a></li>
                    <li :class="listQuery.desc === 2 || listQuery.desc === 3 ? 'on' : ''" @click="toChangeDesc(2)">
                        <a href="javascript:void(0)">
                            <span>价格</span>
                            <div class="icon">
                                <i :class="listQuery.desc === 3 ? 'el-icon-caret-top on' : 'el-icon-caret-top'"></i>
                                <i :class="listQuery.desc === 2 ? 'el-icon-caret-bottom on' : 'el-icon-caret-bottom'"></i>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="price">
                <div class="submit">
                    <el-button type="text" @click="listQuery.startPrice = null, listQuery.endPrice = null">清 空</el-button>
                    <el-button type="primary" @click="toChangePrice">确 定</el-button>
                </div>

                <div class="content">
                    <el-input v-model.number="listQuery.startPrice"> <template slot="prepend">¥</template></el-input>

                    <span class="line">-</span>

                    <el-input v-model.number="listQuery.endPrice"> <template slot="prepend">¥</template></el-input>
                </div>
            </div>
        </div>

        <div class="shop_list_goods" v-loading="isRequestLoading">
            <div class="content">
                <goodsItem v-for="(item, index) in listData" :key="index" :item="item" />
            </div>

            <noDataView v-if="listData.length === 0" />

            <div class="pl24 pr24">
                <pagination :size="listQuery.pageSize" :total="pages.total" :current="listQuery.page" :pages="pages.pages" @pageChange="pageChange" />
            </div>
        </div>
    </div>
</template>

<script>
  import {mapState} from "vuex";
  import {getGoodsList} from "../../../api/shop/goods";
  import {imageURL} from "../../../utils/config";
  const pagination = () => import('../../../components/pagination/shop')
  const noDataView = () => import('../../../components/noDataView')
  const goodsItem = () => import('../../../components/layout/shop/goodsItem')

  export default {
    name: "shopSearch",
    data () {
      return {
        listQuery: {
          name: '',
          desc: 0, // 0-默认 1-销量 2-金额降序 3-金额升序 不传也是默认
          type: 0,
          startPrice: null,
          endPrice: null,
          page: 1,
          pageSize: 15,
          channel: 1
        },
        isRequestLoading: false,
        listData: [],
        pages: {
          total: 0,
          pages: 0
        },
        imageURL: imageURL
      }
    },
    computed: {
      ...mapState({
        listKeepAlive: state => state.app.listKeepAlive,
        goodKeyword: state => state.user.goodKeyword,
      })
    },
    watch: {
      goodKeyword (newVal) {
        this.toSearch(newVal)
      }
    },
    activated () {
      if (this.listKeepAlive.isInitPageNo) {
        this.listQuery.page = 1
      }
      if (this.listKeepAlive.isReset) {
        this.listQuery.name = ''
        this.listQuery.desc = 0
        this.listQuery.type = 0
        this.listQuery.startPrice = null
        this.listQuery.endPrice = null
      }
      if (this.listKeepAlive.isQueryList) {
        this.toSearch(this.goodKeyword)
      }
    },
    methods: {
      toSearch (newVal) {
        this.listQuery.name = newVal
        this.listQuery.desc = 0
        this.listQuery.type = 0
        this.listQuery.startPrice = null
        this.listQuery.endPrice = null
        this.listQuery.page = 1
        this.getList()
      },
      getList () {
        getGoodsList(this.listQuery).then(res => {
          if (res.code === 0) {
            this.listData = res.data.records
            this.pages.total = res.data.total
            this.pages.pages = res.data.pages
          }
        })
      },
      toChangeDesc (val) {
        this.$store.commit('changeScrollTop')
        if (val === 2 && this.listQuery.desc === 2) {
          this.listQuery.desc = 3
        } else {
          this.listQuery.desc = val
        }
        this.listQuery.page = 1
        this.getList()
      },
      toChangePrice () {
        this.$store.commit('changeScrollTop')
        this.listQuery.page = 1
        this.getList()
      },
      pageChange (e) {
        this.$store.commit('changeScrollTop')
        this.listQuery.page = e.current
        this.getList()
      }
    },
    destroyed() {
      this.$store.commit('changeGoodKeyword', '')
    },
    components: {
      pagination, noDataView, goodsItem
    }
  }
</script>
